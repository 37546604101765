import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import HomeSearchPage from './HomeSearchPage';
import BarcodeScanner from './BarcodeScanner';
import GoogleBarcodeScanner from './GoogleBarcodeScanner';
import ProductReview from './ProductReview';
import Database from './Database';
import TopNavBar from './TopNavBar';
import TextSearch from './TextSearch';
import Login from './Login';
import AboutUs from './AboutUs';
import Contact from './Contact';
import Notifications from './Notifications';
import UserList from './UserList';
import FollowButton from './FollowButton';
import UserProducts from './UserProducts';
import Favorites from './Favorites';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import VoiceRecognition from './VoiceRecognition';
import AddNewProduct from './AddNewProduct';
import TakePicture from './TakePicture';
import Blog from './Blog';
import VoiceAddNewProduct from './VoiceAddNewProduct';
import AllReviews from './AllReviews';
import AllProducts from './AllProducts';
import ProductDetail from './ProductDetail';
import SearchUserName from './SearchUserName';
import SearchName from './SearchName';
import Headlines from './Headlines';
import AddReview from './AddReview';
import AdminHeadline from './AdminHeadline';
import Faq from './Faq';
import Following from './Following';
import Followers from './Followers';
import Profile from './Profile';
import BottomNav from './BottomNav';
import CreateHeadline from './CreateHeadline';
import AdminProducts from './AdminProducts';

function App() {
  const [searchCriteria, setSearchCriteria] = useState({});
  const [products, setProducts] = useState([]);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productDeleted, setProductDeleted] = useState(false);
  const [unreadCommentsCount, setUnreadCommentsCount] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  const handleTextSearch = (criteria) => {
    setSearchCriteria(criteria);
  };

  const handleVoiceSearch = (criteria) => {
    console.log("Voice search triggered with:", criteria);
    setSearchCriteria(criteria);
  };

  const handleReviewAdded = useCallback(() => {
    setSearchCriteria({ ...searchCriteria });
  }, [searchCriteria]);

  const handleReviewDeleted = useCallback(() => {
    setSearchCriteria({ ...searchCriteria });
  }, [searchCriteria]);

  const handleImageCaptured = (image) => {
    setCapturedImage(image);
  };
  
  const handleProductDeleted = useCallback(() => {
    setProductDeleted(true);
    setSearchCriteria({ ...searchCriteria });
  }, [searchCriteria]);

  const handleNewComment = (unreadComments) => {
    const totalUnread = Object.values(unreadComments).reduce((acc, count) => acc + count, 0);
    setUnreadCommentsCount(totalUnread);
  };

  if (!authChecked) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <TopNavBar />
        <Routes>
          <Route 
            path="/" 
            element={
              isAuthenticated 
                ? <Navigate to="/all-products" replace /> 
                : <Navigate to="/login" replace />
            } 
          />
          <Route 
            path="/login" 
            element={
              isAuthenticated 
                ? <Navigate to="/all-products" replace /> 
                : <Login />
            } 
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/admin-headlines" element={<AdminHeadline />} />
          <Route path="/created-headlines" element={<CreateHeadline />} />
          <Route path="/search-name" element={<SearchName />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/search-username" element={<SearchUserName />} />
          <Route path="/home-search" element={<HomeSearchPage />} />
          <Route path="/blog" element={<Blog onNewComment={handleNewComment} />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/headlines" element={<Headlines />} />
          <Route path="/scan-database" element={<BarcodeScanner />} />
          <Route path="/scan-google" element={<GoogleBarcodeScanner />} />
          <Route path="/user-products/:username" element={<UserProducts />} />
          <Route path="/voice-add-new-product" element={<VoiceAddNewProduct />} />
          <Route path="/text-search" element={<TextSearch onSearch={handleTextSearch} />} />
          <Route path="/voice-search" element={<VoiceRecognition onSearch={handleVoiceSearch} />} />
          <Route 
            path="/product" 
            element={
              <>
                <Database 
                  searchCriteria={searchCriteria}
                  setProducts={setProducts}
                  setIsLoading={setIsLoading}
                  productDeleted={productDeleted}
                  setProductDeleted={setProductDeleted}
                />
                <ProductReview 
                  products={products}
                  onReviewAdded={handleReviewAdded}
                  onReviewDeleted={handleReviewDeleted}
                  onProductDeleted={handleProductDeleted}
                  isLoading={isLoading}
                />
              </>
            } 
          />
          <Route path="/add-product" element={<AddNewProduct capturedImage={capturedImage} />} />
          <Route path="/take-picture" element={<TakePicture onImageCaptured={handleImageCaptured} />} />
          <Route path="/voice-add-product" element={<VoiceAddNewProduct />} />
          <Route path="/all-reviews" element={<AllReviews />} />
          <Route 
            path="/all-products" 
            element={
              isAuthenticated 
                ? <AllProducts /> 
                : <Navigate to="/login" replace />
            } 
          />
          <Route path="/product/:productId" element={<ProductDetail />} />
          <Route path="/followers" element={<Followers />} />
          <Route path="/following" element={<Following />} />
          <Route path="/notifications" element={<Notifications onNewNotification={setUnreadCommentsCount} />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/add-review/:productId" element={<AddReview />} />
          <Route path="/admin/products" element={<AdminProducts />} />
          <Route path="/user-list" element={<UserList />} />
          <Route path="/follow/:targetUserId" element={<FollowButton />} />
        </Routes>
        
        {isAuthenticated && <BottomNav unreadNotificationsCount={unreadCommentsCount} />}
      </div>
    </Router>
  );
}

export default App;