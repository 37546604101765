import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import app from './firebaseConfig';
import './Followers.css';

const Followers = () => {
  const [followers, setFollowers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth(app);
  const db = getDatabase(app);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      setIsLoading(false);
      return;
    }

    const followersRef = ref(db, `users/${user.uid}/followers`);
    const unsubscribe = onValue(followersRef, async (snapshot) => {
      if (snapshot.exists()) {
        const followersData = snapshot.val();
        const followersList = await Promise.all(
          Object.keys(followersData).map(async (userId) => {
            const userRef = ref(db, `users/${userId}`);
            const userSnapshot = await get(userRef);
            const userData = userSnapshot.val();
            return {
              userId,
              username: userData?.username || followersData[userId].username || 'Unknown User'
            };
          })
        );
        setFollowers(followersList);
      } else {
        setFollowers([]);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth, db]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="followers-page">
      <h2>Followers</h2>
      {followers.length === 0 ? (
        <p>You don't have any followers yet.</p>
      ) : (
        <ul>
          {followers.map(({ userId, username }) => (
            <li key={userId}>
              <Link to={`/user-products/${encodeURIComponent(username)}`}>{username}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Followers;