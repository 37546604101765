import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaMicrophone, FaTrash, FaPlus } from 'react-icons/fa';
import './VoiceRecognition.css';

function VoiceRecognition({ onSearch }) {
    const [transcript, setTranscript] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [error, setError] = useState(null);
    const [searchType, setSearchType] = useState('database');
    const [searchField, setSearchField] = useState('productName');
    const recognitionRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if ('webkitSpeechRecognition' in window) {
            const SpeechRecognition = window.webkitSpeechRecognition;
            recognitionRef.current = new SpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;
            recognitionRef.current.lang = 'en-US';

            recognitionRef.current.onresult = (event) => {
                const current = event.resultIndex;
                const cleanedTranscript = event.results[current][0].transcript
                    .replace(/[.,/#!$%^&*;:{}=\-_`'~()]/g, "")
                    .replace(/\bcomma\b/gi, ",")
                    .trim();
                setTranscript(cleanedTranscript);
            };

            recognitionRef.current.onerror = (event) => {
                if (event.error !== 'no-speech') {
                    setError(`Error: ${event.error}`);
                    setIsListening(false);
                }
            };

            recognitionRef.current.onend = () => setIsListening(false);
        } else {
            setError("Speech Recognition not supported in this browser");
        }

        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.abort();
            }
        };
    }, []);

    const toggleListening = () => {
        if (isListening) {
            recognitionRef.current.stop();
        } else {
            setError(null);
            setTranscript('');
            recognitionRef.current.start();
        }
        setIsListening(!isListening);
    };

    const handleReturnToSearchPage = () => {
        navigate('/home-search');
    };

    const handleSubmit = () => {
        let url;
        switch(searchType) {
            case 'database':
                const filters = {
                    description: '',
                    brand: '',
                    whereSeen: '',
                };
                if (searchField === 'productDescription') {
                    filters.description = transcript;
                } else if (searchField === 'productName') {
                    filters.brand = transcript;
                } else if (searchField === 'whereSeen') {
                    filters.whereBought = transcript;
                }
                navigate('/all-products', { state: { filters } });
                return;
            case 'amazon':
                url = `https://www.amazon.com/s?k=${encodeURIComponent(transcript)}`;
                break;
            case 'reviews':
                url = `https://www.google.com/search?q=${encodeURIComponent(transcript + ' reviews')}`;
                break;
            case 'bing':
                url = `https://www.bing.com/search?q=${encodeURIComponent(transcript + ' reviews')}`;
                break;
            case 'youtube':
                url = `https://www.youtube.com/results?search_query=${encodeURIComponent(transcript + ' reviews')}`;
                break;
            case 'imdb':
                url = `https://www.imdb.com/find?q=${encodeURIComponent(transcript + ' reviews')}`;
                break;
            case 'tripadvisor':
                url = `https://www.tripadvisor.com/Search?q=${encodeURIComponent(transcript + ' reviews')}`;
                break;
            case 'yelp':
                url = `https://www.yelp.com/search?find_desc=${encodeURIComponent(transcript + ' reviews')}`;
                break;
            default:
                url = `https://www.google.com/search?q=${encodeURIComponent(transcript)}`;
        }
        window.open(url, '_blank');
    };

    const clearTranscript = () => {
        setTranscript('');
    };

    return (
        <div className="voice-recognition">
            <div className="recognition-content">
                <h2>Use Voice to Search</h2>
                <FaMicrophone 
                    className={`mic-icon ${isListening ? 'active' : ''}`} 
                    onClick={toggleListening}
                    size={50}
                />
                <p>{isListening ? 'Listening...' : 'Click to start'}</p>
                {error && <p className="error-message">{error}</p>}
                <div className="transcript-container">
                    <p className="transcript">{transcript}</p>
                </div>
                <button className="clear-button" onClick={clearTranscript}>
                    <FaTrash /> Clear Text
                </button>
                <div className="search-options">
                    <div className="search-type">
                        <h3>Search In:</h3>
                        {['database', 'reviews', 'amazon', 'web', 'youtube', 'bing', 'imdb', 'tripadvisor', 'yelp'].map((type) => (
                            <label key={type}>
                                <input 
                                    type="radio" 
                                    value={type} 
                                    checked={searchType === type} 
                                    onChange={() => setSearchType(type)}
                                />
                                {type === 'database' ? 'Local Database' :
                                 type === 'reviews' ? 'Google for Reviews' :
                                 type === 'amazon' ? 'Search Amazon' :
                                 type === 'web' ? 'Web in General' :
                                 type === 'youtube' ? 'Search YouTube' :
                                 type === 'bing' ? 'Bing for Reviews' :
                                 type === 'imdb' ? 'IMDB for Movie Reviews' :
                                 type === 'tripadvisor' ? 'TripAdvisor for Hotels, Restaurants and Places' :
                                 'Yelp for Reviews'}
                            </label>
                        ))}
                    </div>
                    {searchType === 'database' && (
                        <div className="search-field">
                            <h3>Search Field:</h3>
                            <p className="select-one">select one</p>
                            <label>
                                <input 
                                    type="radio" 
                                    value="productName" 
                                    checked={searchField === 'productName'} 
                                    onChange={() => setSearchField('productName')}
                                />
                                Item Name
                            </label>
                            <label>
                                <input 
                                    type="radio" 
                                    value="productDescription" 
                                    checked={searchField === 'productDescription'} 
                                    onChange={() => setSearchField('productDescription')}
                                />
                                Item Description
                            </label>
                            <label>
                                <input 
                                    type="radio" 
                                    value="whereSeen" 
                                    checked={searchField === 'whereSeen'} 
                                    onChange={() => setSearchField('whereSeen')}
                                />
                                Where Seen
                            </label>
                        </div>
                    )}
                </div>
                <button className="submit-button" onClick={handleSubmit} disabled={!transcript}>
                    Submit
                </button>
                <div className="navigation-links">
                    <Link to="/voice-add-product" className="add-product-button">
                        <FaPlus className="plus-icon" />
                        Add New Item
                    </Link>
                </div>
                <button onClick={handleReturnToSearchPage}>
                    Return to Search Page
                </button>
            </div>
        </div>
    );
}

export default VoiceRecognition;