// FAQ.js
import React from 'react';
import { Link } from 'react-router-dom';
import './FAQ.css';

function FAQ() {
    return (
        <div className="faq-container">
            <h1>Frequently Asked Questions</h1>
            <Link to="/all-products" className="back-button">Back to All Items</Link>
            <div className="faq-item">
            <h2>Why do I need this app?</h2>
                <p>Ever go to the store and you see something new on the shelf and ask yourself  "I wonder if that is any good?"  Well, now all you need to do is point your phone at the barcode and if it's in our database <strong>you can instantly see what others think about the product!</strong> If it isn't there, the app offers multiple other ways of searching for reviews. No guesswork or risking buying something you may not like.  New reviews are added daily from people just like you!
                </p>
                <h2>How do I search this database?</h2>
                <p>
                    On the opening page, you will see the files in the database. 
                    To narrow these down, <strong>start typing in the name of a product, person, place or thing you would like to search in the filter field on the left sidebar. </strong> You can show or hide this sidebar to make more room for products that show up. 
                    The list will automatically start removing anything that doesn't match. <strong>You may not notice anything happening because the list is long and the removed items may be farther down the page.</strong></p>
                   <p> The same goes with the brand name or anything that might help refine the search. 
                    Also where it was bought or seen in the third filter. 
                    Finally <strong>select the number of stars you would like to see,</strong> for instance, only five stars.  Additionally if you click the <strong>View Following Only </strong>button you will only see products from people you are following.
                </p>
                <h2>What if I dont find my item?</h2>
                <p>
                  Go to the <strong>search page.</strong>  There you will find several ways to search. First you can search by <strong>scanning a barcode of a product.</strong>   If it is in the database, it should show up.  <strong>If not try scanning Google with the second button.</strong>  This will scan the barcode and search Google for the product and will see if there are any reviews about it.  
                </p>
                <h2>How do I view reviews?</h2>
                <p>Click on the picture of any item found in the database.  If a review exists you will be able to read it.  If not, consider adding your own review.
                </p>
                <h2>What if the thing I'm searching for doesnt have a barcode?

                </h2>
                <p>
                Try the <strong>Voice or Text Search buttons.</strong>  Either one will let you search by product name, description, where you saw it or bought it.  You can search Amazon, Google, Yelp, Trip Advisor, YouTube, etc.  Chances are something will show up. 
                </p>
                <h2>What if I cant read the barcode?</h2>
                <p>
                Try the <strong>Voice or Text Search buttons.</strong>  Either one will let you search by product name, description, where you saw it or bought it.  You can search Amazon, Google, Yelp, Trip Advisor, YouTube, etc.  Chances are something will show up. 
                </p>
                <h2>What if I want to add a product?
                    </h2>
                    <p>
 Click on the Add Item button. There you can add a barcode if it has one or <strong>type unknown if it doesnt.</strong>  You can add a description, product name, where bought or seen, and a picture of it.  It can be anything.  A person, place, or thing. <strong>Type in unknown if you dont know the info for a field. </strong> You can also add a product from the <strong>Text Search or Voice Search Page.</strong>
                    </p>
                    <h2>What is a quick way to add an item?
                    </h2>
                    <p>
 Click on the Barcode Scan button. Scan the barcode of the product.<strong>If is doesn't find the product, click on Add Product</strong>  The barcode will already be filled in. Then all you have to add is the description, brand name and where seen and take a picture.
                    </p>
                    <h2>How do I add a review?</h2>
                <p>
      Once the product is in the database, a blue button will appear on the top that says View Reviews.  Click it and it will take you to the reviews page. Click on Add Review and follow the steps. Be sure to include a star rating!
                </p>  
                <h2>Can I review my own item?</h2>
                <p>
   Yes! Try not to make the reviews too long though.  Every review has the username of the person making it so it will be obvious is someone makes a whole lot of reviews of their own listing.  It also contains the date the review was submitted.  <strong>Any objectionable reviews or listings will be deleted.</strong>
                </p>   
               
   
        
                
                
            </div>
            {/* Add more FAQ items here */}
        
        </div>
    );
}

export default FAQ;