import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, onValue, remove } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import app from './firebaseConfig';
import './Following.css';

const Following = () => {
  const [following, setFollowing] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth(app);
  const db = getDatabase(app);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      setIsLoading(false);
      return;
    }

    // Fetch following list
    const followingRef = ref(db, `follows/${user.uid}`);
    const unsubscribeFollowing = onValue(followingRef, async (snapshot) => {
      if (snapshot.exists()) {
        const followingData = snapshot.val();
        const followingList = await Promise.all(
          Object.keys(followingData).map(async (userId) => {
            const userSnapshot = await get(ref(db, `users/${userId}`));
            const userData = userSnapshot.val();
            return {
              userId,
              username: userData?.username || 'Unknown',
              displayName: userData ? `${userData.firstName} ${userData.lastName}` : 'Unknown'
            };
          })
        );
        setFollowing(followingList);
      } else {
        setFollowing([]);
      }
      setIsLoading(false);
    });

    // Fetch followers list
    const followersRef = ref(db, `followers/${user.uid}`);
    const unsubscribeFollowers = onValue(followersRef, async (snapshot) => {
      if (snapshot.exists()) {
        const followersData = snapshot.val();
        const followersList = await Promise.all(
          Object.keys(followersData).map(async (userId) => {
            const userSnapshot = await get(ref(db, `users/${userId}`));
            const userData = userSnapshot.val();
            return {
              userId,
              username: userData?.username || 'Unknown',
              displayName: userData ? `${userData.firstName} ${userData.lastName}` : 'Unknown'
            };
          })
        );
        setFollowers(followersList);
      } else {
        setFollowers([]);
      }
      setIsLoading(false);
    });

    return () => {
      unsubscribeFollowing();
      unsubscribeFollowers();
    };
  }, [auth, db]);

  const handleUnfollow = (userId) => {
    const user = auth.currentUser;
    if (user) {
      const unfollowRef = ref(db, `follows/${user.uid}/${userId}`);
      const followerRef = ref(db, `followers/${userId}/${user.uid}`);
      remove(unfollowRef);
      remove(followerRef);
      setFollowing((prev) => prev.filter((f) => f.userId !== userId));
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="following-page">
      <h2>Following</h2>
      <ul>
        {following.map(({ userId, username, displayName }) => (
          <li key={userId}>
            <Link to={`/user-products/${encodeURIComponent(username)}`} state={{ showFavorites: true }}>
              {displayName}
            </Link>
            <button onClick={() => handleUnfollow(userId)}>Unfollow</button>
          </li>
        ))}
      </ul>
      <h2>Followers</h2>
      <ul>
        {followers.map(({ userId, username, displayName }) => (
          <li key={userId}>
            <Link to={`/user-products/${encodeURIComponent(username)}`} state={{ showFavorites: true }}>
              {displayName}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Following;