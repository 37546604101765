import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import app from './firebaseConfig';
import { getDatabase, ref, get } from 'firebase/database';
import './Database.css';

const NoProductsFound = () => (
  <div className="database-container">
    <div className="search-buttons">
      <h2>No products found. Try another search:</h2>
      <Link to="/text-search" className="search-button">Text Search</Link>
      <Link to="/voice-search" className="search-button">Voice Search</Link>
      <Link to="/scan-database" className="search-button">Barcode Search</Link>
      <Link to="/" className="search-button">Home</Link>
    </div>
  </div>
);

const Database = ({ searchCriteria, setProducts, setIsLoading }) => {
    const [noProductsFound, setNoProductsFound] = useState(false);

    const fetchData = useCallback(async () => {
        console.log("Fetching data...");
        console.log("Search criteria:", searchCriteria);
        setIsLoading(true);
        try {
            const db = getDatabase(app);
            const productsRef = ref(db, 'nature/products');
            const snapshot = await get(productsRef);

            if (snapshot.exists()) {
                const data = snapshot.val();
                console.log("Data received:", data);
                const productData = Object.entries(data).map(([id, item]) => {
                    const reviews = item.reviews 
                        ? Object.entries(item.reviews).map(([reviewId, review]) => ({
                            id: reviewId,
                            ...review
                          }))
                        : [];
                    return {
                        id,
                        ...item,
                        reviews
                    };
                });
                console.log("Product data before filtering:", productData);

                const matchingProducts = productData.filter((item) => {
                    return Object.entries(searchCriteria).some(([key, value]) => {
                      if (!value) return false;
                      if (key === 'tags') {
                        console.log(`Searching for tag:`, value);
                        console.log(`Type of tag value:`, typeof value);
                        
                        const searchTags = Array.isArray(value) ? value : [value];
                        
                        return item.tags && item.tags.some(tag => {
                          return searchTags.some(searchTag => {
                            if (typeof searchTag !== 'string') {
                              console.log(`Invalid search tag:`, searchTag);
                              return false;
                            }
                            const match = tag.toLowerCase().includes(searchTag.toLowerCase().trim());
                            console.log(`Tag ${tag} match with ${searchTag}:`, match);
                            return match;
                          });
                        });
                      }
                      const match = item[key] && typeof item[key] === 'string' && item[key].toLowerCase().includes(value.toLowerCase().trim());
                      console.log(`Checking ${key} for product ${item.id}:`, item[key], `Match:`, match);
                      return match;
                    });
                });
      
                console.log("Matching products:", matchingProducts);
      
                if (matchingProducts.length > 0) {
                    setProducts(matchingProducts);
                    setNoProductsFound(false);
                } else {
                    setProducts([]);
                    setNoProductsFound(true);
                    console.log('No matching products found');
                }
            } else {
                console.log('No data exists in the database');
                setProducts([]);
                setNoProductsFound(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setProducts([]);
            setNoProductsFound(true);
        } finally {
            setIsLoading(false);
        }
    }, [searchCriteria, setProducts, setIsLoading]);

    useEffect(() => {
        if (Object.values(searchCriteria).some(value => value)) {
            fetchData();
        }
        // Reset the state when component unmounts or search criteria change
        return () => {
            setNoProductsFound(false);
        };
    }, [searchCriteria, fetchData]);

    // Only render the NoProductsFound component when necessary
    if (noProductsFound) {
        return <NoProductsFound />;
    }

    return null;
};

export default Database;