import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import app from './firebaseConfig';
import StarRating from './StarRating';
import './AllReviews.css';

const AllReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase(app);
    const reviewsRef = ref(db, 'nature/products');
    onValue(reviewsRef, (snapshot) => {
      const data = snapshot.val();
      const allReviews = [];
      for (const productId in data) {
        const product = data[productId];
        if (product.reviews) {
          for (const reviewId in product.reviews) {
            allReviews.push({ ...product.reviews[reviewId], productId, productName: product.productDescription, brand: product.brand, whereBought: product.whereBought, imageUrl: product.imageUrl });
          }
        }
      }
      setReviews(allReviews);
      setIsLoading(false);
    }, (error) => {
      console.error("Error fetching data:", error);
      setError(error);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading reviews: {error.message}</div>;
  }

  return (
    <div className="all-reviews-container">
      <button className="home-button" onClick={() => navigate('/')}>Return to Home</button>
      <h2>All Reviews</h2>
      <div className="reviews-grid">
        {reviews.map((review, index) => (
          <div 
            key={index} 
            className="review-card" 
            onClick={() => {
              console.log(`Navigating to product ${review.productId}`);
              navigate(`/product/${review.productId}`);
            }}
          >
            <h3>{review.productName}</h3>
            <p>Brand: {review.brand}</p>
            <p>Purchased at: {review.whereBought}</p>
            <p>{review.review}</p>
            <StarRating rating={review.rating} onRatingChange={() => {}} />
            {review.imageUrl && <img src={review.imageUrl} alt="Review" className="review-image" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllReviews;
