import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getDatabase, ref, push, onValue, remove, get } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import app from './firebaseConfig';
import './Blog.css';
import defaultProfilePic from './default-profile-pic.png';

const LineBreak = ({ text }) => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== text.split('\n').length - 1 && <br />}
    </React.Fragment>
  ));
};

const Blog = ({ onNewComment }) => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState({ title: '', content: '', image: null });
  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState('');
  const [profilePicture, setProfilePicture] = useState(defaultProfilePic);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [postLikes, setPostLikes] = useState({});
  const [commentLikes, setCommentLikes] = useState({});
  const [viewMode, setViewMode] = useState('all');
  const [following, setFollowing] = useState([]);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);

  const auth = getAuth(app);
  const db = getDatabase(app);
  const storage = getStorage(app);

  const fetchFollowing = useCallback(async (userId) => {
    const followingRef = ref(db, `follows/${userId}`);
    const snapshot = await get(followingRef);
    if (snapshot.exists()) {
      setFollowing(Object.keys(snapshot.val()));
    } else {
      setFollowing([]);
    }
  }, [db]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchFollowing(user.uid);
      } else {
        setCurrentUser(null);
        setFollowing([]);
      }
    });

    return () => unsubscribe();
  }, [auth, fetchFollowing]);

  useEffect(() => {
    const postsRef = ref(db, 'blog/posts');
    const commentsRef = ref(db, 'blog/comments');

    const unsubscribePosts = onValue(postsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const postList = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setPosts(postList);
      } else {
        setPosts([]);
      }
    });

    const unsubscribeComments = onValue(commentsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setComments(data);
        const unread = {};
        Object.keys(data).forEach(postId => {
          unread[postId] = Object.keys(data[postId]).length;
        });
        onNewComment(unread);
      } else {
        setComments({});
      }
    });

    return () => {
      unsubscribePosts();
      unsubscribeComments();
    };
  }, [db, onNewComment]);

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(db, `users/${currentUser.uid}`);
      onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setProfilePicture(data.photoURL || defaultProfilePic);
        }
      });
    }
  }, [currentUser, db]);

  const handlePostChange = (e) => {
    const { name, value, files } = e.target;
    setNewPost(prev => ({
      ...prev,
      [name]: files ? files[0] : value
    }));
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      let imageUrl = '';
      if (newPost.image) {
        const imageRef = storageRef(storage, `blog-images/${Date.now()}_${newPost.image.name}`);
        await uploadBytes(imageRef, newPost.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const postRef = ref(db, 'blog/posts');
      const newPostRef = push(postRef, {
        ...newPost,
        content: newPost.content,
        imageUrl,
        author: user.displayName || user.email,
        authorProfilePicture: profilePicture,
        createdAt: Date.now(),
        authorId: user.uid
      });

      const notificationRef = ref(db, `notifications/${user.uid}`);
      push(notificationRef, {
        message: `${user.displayName || user.email} has added a new post`,
        timestamp: Date.now(),
        type: 'newPost',
        postId: newPostRef.key,
        read: false
      });

      setNewPost({ title: '', content: '', image: null });
      setCapturedImage(null);
    }
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };
  const toggleViewMode = () => {
    setViewMode(prevMode => prevMode === 'all' ? 'following' : 'all');
  };

  const filteredPosts = viewMode === 'all' ? posts : posts.filter(post => following.includes(post.authorId));
  const handleCommentSubmit = (postId) => {
    const user = auth.currentUser;
    if (user && newComment.trim()) {
      const commentRef = ref(db, `blog/comments/${postId}`);
      const newCommentRef = push(commentRef, {
        content: newComment,
        author: user.displayName || user.email,
        authorProfilePicture: profilePicture,
        createdAt: Date.now(),
        authorId: user.uid
      });

      const notificationRef = ref(db, `notifications/${user.uid}`);
      push(notificationRef, {
        message: `${user.displayName || user.email} has added a comment on your post`,
        timestamp: Date.now(),
        type: 'newComment',
        postId: postId,
        commentId: newCommentRef.key,
        read: false
      });

      setNewComment('');
    }
  };

  const handleDeletePost = (postId) => {
    const postRef = ref(db, `blog/posts/${postId}`);
    remove(postRef)
      .then(() => {
        console.log('Post deleted successfully');
      })
      .catch((error) => {
        console.error('Error deleting post:', error);
      });
  };

  const handleDeleteComment = (postId, commentId) => {
    const commentRef = ref(db, `blog/comments/${postId}/${commentId}`);
    remove(commentRef)
      .then(() => {
        console.log('Comment deleted successfully');
      })
      .catch((error) => {
        console.error('Error deleting comment:', error);
      });
  };

  const handleLikePost = (postId) => {
    setPostLikes(prev => ({
      ...prev,
      [postId]: (prev[postId] || 0) + 1
    }));
  };

  const handleLikeComment = (postId, commentId) => {
    setCommentLikes(prev => ({
      ...prev,
      [postId]: {
        ...prev[postId],
        [commentId]: (prev[postId]?.[commentId] || 0) + 1
      }
    }));
  };

  const handleTakePicture = () => {
    setIsCameraActive(true);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        })
        .catch((error) => {
          console.error('Error accessing the camera:', error);
        });
    }
  };

  const handleCapturePicture = () => {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    canvasRef.current.toBlob((blob) => {
      setCapturedImage(blob);
      handleStopCamera();
    });
  };

  const handleStopCamera = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      videoRef.current.srcObject = null;
    }
  };
  
  const handleCancelPicture = () => {
    setIsCameraActive(false);
    handleStopCamera();
  };
  
  const handleRetakePicture = () => {
    setCapturedImage(null);
    handleTakePicture();
  };

  return (
    <div className="blog-container">
    <h2>Forum</h2>
    <h3>Share your favorite things here!</h3>
    <h4>Recipes, products, places, restaurants, attractions, hotels, etc.</h4>
    <div className="view-mode-buttons">
      <button onClick={toggleViewMode} className="active">
        {viewMode === 'all' ? 'View following only' : 'View all posts'}
      </button>
    </div>
      <form onSubmit={handlePostSubmit} className="new-post-form">
        <input
          type="text"
          name="title"
          value={newPost.title}
          onChange={handlePostChange}
          placeholder="Post Title"
          required
        />
        <textarea
          name="content"
          value={newPost.content}
          onChange={handlePostChange}
          placeholder="Write your post..."
          required
        />
        <input
          type="file"
          name="image"
          onChange={handlePostChange}
          accept="image/*"
        />
        <button type="button" onClick={handleTakePicture}>Take Picture</button>
        {isCameraActive && (
          <div className="camera-container">
            <video ref={videoRef} className="camera-video" />
            <button type="button" onClick={handleCapturePicture}>Capture Picture</button>
            <button type="button" onClick={handleCancelPicture}>Cancel</button>
          </div>
        )}
        {capturedImage && (
          <div className="captured-image-container">
            <img src={URL.createObjectURL(capturedImage)} alt="Captured" className="captured-image" />
            <button type="button" onClick={handleRetakePicture}>Retake Picture</button>
            <button type="submit">Upload Picture</button>
          </div>
        )}
        <canvas ref={canvasRef} style={{ display: 'none' }} width="320" height="240" />
        <button type="submit">Add Post</button>
      </form>
  
      <div className="posts-container">
        {filteredPosts.map(post => (
          <div key={post.id} className="post">
             <div className="post-header">
              <img 
                src={post.authorProfilePicture || defaultProfilePic} 
                alt="Author" 
                className="author-profile-picture" 
              />
              <div className="author-info">
                <span className="author-name">{post.author}</span>
                <span className="post-date">{new Date(post.createdAt).toLocaleString()}</span>
              </div>
            </div>
            <h2>{post.title}</h2>
            <p><LineBreak text={post.content} /></p>
            {post.imageUrl && <img src={post.imageUrl} alt="Post" className="post-image" />}
            {auth.currentUser && auth.currentUser.uid === post.authorId && (
              <button onClick={() => handleDeletePost(post.id)}>Delete Post</button>
            )}
            <button onClick={() => handleLikePost(post.id)}>👍 {postLikes[post.id] || 0}</button>
            
            <div className="comments">
              <h3>Comments</h3>
              {comments[post.id] && Object.entries(comments[post.id]).map(([commentId, comment]) => (
                <div key={commentId} className={`comment ${!comment.read ? 'unread-comment' : ''}`}>
                  <div className="comment-header">
                    <img 
                      src={comment.authorProfilePicture || defaultProfilePic} 
                      alt="Commenter" 
                      className="commenter-profile-picture" 
                    />
                    <div className="commenter-info">
                      <span className="commenter-name">{comment.author}</span>
                      <span className="comment-date">{new Date(comment.createdAt).toLocaleString()}</span>
                    </div>
                  </div>
                  <p>{comment.content}</p>
                  <div className="comment-actions">
                    <button onClick={() => handleLikeComment(post.id, commentId)}>👍 {commentLikes[post.id]?.[commentId] || 0}</button>
                    {auth.currentUser && auth.currentUser.uid === comment.authorId && (
                      <button onClick={() => handleDeleteComment(post.id, commentId)}>Delete Comment</button>
                    )}
                  </div>
                </div>
              ))}
              <form onSubmit={(e) => { e.preventDefault(); handleCommentSubmit(post.id); }}>
                <input
                  type="text"
                  value={newComment}
                  onChange={handleCommentChange}
                  placeholder="Add a comment"
                  required
                />
                <button type="submit">Comment</button>
              </form>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;