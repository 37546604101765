import React, { useEffect, useState, useCallback } from 'react';
import { getDatabase, ref, get, remove } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import StarRating from './StarRating';
import { Link, useNavigate } from 'react-router-dom';
import './favorites.css';

const Favorites = () => {
  const [favProducts, setFavProducts] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null);
  const navigate = useNavigate();

  const calculateAverageRating = (reviews) => {
    if (!reviews) return 0;
    const ratings = Object.values(reviews).map((review) => Number(review.rating));
    return ratings.length > 0 ? ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length : 0;
  };

  const fetchUsernames = async (userIds) => {
    const db = getDatabase(app);
    const usernames = {};

    for (const userId of userIds) {
      const userRef = ref(db, `users/${userId}`);
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        usernames[userId] = snapshot.val().username;
      }
    }

    return usernames;
  };

  const fetchFavProducts = useCallback(async (favoritesData) => {
    const db = getDatabase(app);
    const productsRef = ref(db, 'nature/products');
    const snapshot = await get(productsRef);

    if (snapshot.exists()) {
      const data = snapshot.val();
      const favProducts = [];
      const userIds = new Set();

      for (const [id, favoriteInfo] of Object.entries(favoritesData)) {
        const productData = data[id];
        if (!productData) {
          console.warn(`Product with id ${id} not found in database`);
          continue;
        }
        favProducts.push({
          ...productData,
          productId: id,
          dateAdded: favoriteInfo.dateAdded,
          averageRating: calculateAverageRating(productData.reviews),
        });
        userIds.add(productData.addedBy);
      }

      const usernames = await fetchUsernames([...userIds]);

      const favProductsWithUsernames = favProducts.map(product => ({
        ...product,
        addedByUsername: usernames[product.addedBy] || 'Anonymous'
      }));

      setFavProducts(favProductsWithUsernames);
    } else {
      console.log('No products found in the database');
      setFavProducts([]);
    }
  }, []);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserId(user.uid);
        const db = getDatabase(app);
        const favRef = ref(db, `users/${user.uid}/favorites`);
        get(favRef)
          .then((snapshot) => {
            if (snapshot.exists()) {
              const favoritesData = snapshot.val();
              fetchFavProducts(favoritesData);
            } else {
              setFavProducts([]);
            }
          })
          .catch((error) => {
            console.error('Error fetching favorite products:', error);
          });
      }
    });

    return () => unsubscribe();
  }, [fetchFavProducts]);

  const handleUnfavorite = (productId) => {
    if (!currentUserId) {
      alert("You need to be logged in to unfavorite products.");
      return;
    }

    const db = getDatabase(app);
    const favRef = ref(db, `users/${currentUserId}/favorites/${productId}`);
    remove(favRef)
      .then(() => {
        setFavProducts(favProducts.filter(product => product.productId !== productId));
        console.log("Product removed from favorites");
      })
      .catch((error) => console.error("Error removing favorite:", error));
  };

  return (
    <div className="favorites-page">
      <h2>My Favritz</h2>
      {favProducts.length === 0 ? (
        <p>You haven't Favritzed any products yet.</p>
      ) : (
        <div className="products-grid">
          {favProducts.map((product, index) => (
            <div key={index} className="product-card">
              <div className="product-image-container">
                {product.imageUrl && <img src={product.imageUrl} alt="Product" className="product-image" />}
              </div>
              <div className="product-details">
                {product.productDescription && <h3>{product.productDescription}</h3>}
                {product.brand && <p>Brand: {product.brand}</p>}
                {product.whereBought && <p>Where seen: {product.whereBought}</p>}
                <StarRating rating={product.averageRating || 0} onRatingChange={() => {}} readonly size={15} />
                <p className="added-info">
                  Added on: {new Date(product.dateAdded).toLocaleDateString()}
                  <br />
                  By: <Link to={`/user-products/${encodeURIComponent(product.addedByUsername)}`} state={{ showFavorites: true }}>{product.addedByUsername}</Link>
                </p>
              </div>
              <div className="product-actions">
                <button onClick={() => navigate(`/product/${product.productId}`)}>View Reviews</button>
                <button onClick={() => handleUnfavorite(product.productId)}>Unfavritz</button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Favorites;