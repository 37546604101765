import React, { useEffect, useState, useRef } from 'react';
import { getDatabase, ref, onValue, update} from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import Webcam from 'react-webcam';
import app from './firebaseConfig';
import './Profile.css';

const Profile = () => {
  const [profileData, setProfileData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    followingCount: 0,
    followersCount: 0,
    photoURL: '',
    productsAddedCount: 0,
    productsFavoritedCount: 0,
    reviewsAddedCount: 0,
    productsFavoritedByOthersCount: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [useWebcam, setUseWebcam] = useState(false);
  const fileInputRef = useRef(null);
  const webcamRef = useRef(null);

  useEffect(() => {
    const auth = getAuth(app);
    const user = auth.currentUser;

    if (user) {
      const db = getDatabase(app);
      const userRef = ref(db, `users/${user.uid}`);
      onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setProfileData((prevState) => ({
            ...prevState,
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            username: data.username || '',
            likesCount: data.likesCount || 0,
            photoURL: data.photoURL || ''
          }));
        }
        setIsLoading(false);
      });

      // Fetch followers count
      const followersRef = ref(db, `followers/${user.uid}`);
      onValue(followersRef, (snapshot) => {
        const followersCount = snapshot.exists() ? Object.keys(snapshot.val()).length : 0;
        setProfileData((prevState) => ({
          ...prevState,
          followersCount
        }));
      });

      // Fetch following count
      const followingRef = ref(db, `follows/${user.uid}`);
      onValue(followingRef, (snapshot) => {
        const followingCount = snapshot.exists() ? Object.keys(snapshot.val()).length : 0;
        setProfileData((prevState) => ({
          ...prevState,
          followingCount
        }));
      });

      // Fetch products added count, reviews added count, and products favorited by others count
      const productsRef = ref(db, 'nature/products');
      onValue(productsRef, (snapshot) => {
        if (snapshot.exists()) {
          const products = snapshot.val();
          let productsAddedCount = 0;
          let reviewsAddedCount = 0;
          let productsFavoritedByOthersCount = 0;

          Object.values(products).forEach(product => {
            if (product.addedBy === user.uid) {
              productsAddedCount++;
              if (product.favorites) {
                productsFavoritedByOthersCount += Object.keys(product.favorites).length;
              }
            }
            if (product.reviews) {
              Object.values(product.reviews).forEach(review => {
                if (review.userId === user.uid) {
                  reviewsAddedCount++;
                }
              });
            }
          });

          setProfileData((prevState) => ({
            ...prevState,
            productsAddedCount,
            reviewsAddedCount,
            productsFavoritedByOthersCount
          }));
        }
      });

      // Fetch products favorited count
      const favoritesRef = ref(db, `users/${user.uid}/favorites`);
      onValue(favoritesRef, (snapshot) => {
        const productsFavoritedCount = snapshot.exists() ? Object.keys(snapshot.val()).length : 0;
        setProfileData((prevState) => ({
          ...prevState,
          productsFavoritedCount
        }));
      });
    }
  }, []);

  const handleProfilePictureUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePicture(file);
      setCroppedImage(null);
      if (fileInputRef.current) {
        fileInputRef.current.style.backgroundImage = `url(${URL.createObjectURL(file)})`;
        fileInputRef.current.style.backgroundSize = 'cover';
        fileInputRef.current.style.backgroundPosition = 'center';
      }
    }
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCroppedImage(imageSrc);
    setUseWebcam(false);
  };

  const uploadProfilePicture = async () => {
    if (croppedImage) {
      const auth = getAuth(app);
      const user = auth.currentUser;

      if (user) {
        const storageReference = storageRef(getStorage(app), `users/${user.uid}/profile-picture`);
        await uploadBytes(storageReference, await fetch(croppedImage).then((res) => res.blob()));
        const photoURL = await getDownloadURL(storageReference);

        const db = getDatabase(app);
        const userRef = ref(db, `users/${user.uid}`);
        await update(userRef, { photoURL });

        setProfileData((prevState) => ({
          ...prevState,
          photoURL
        }));
        setProfilePicture(null);
        setCroppedImage(null);
        if (fileInputRef.current) {
          fileInputRef.current.style.backgroundImage = '';
        }
      }
    } else if (profilePicture) {
      const auth = getAuth(app);
      const user = auth.currentUser;

      if (user) {
        const storageReference = storageRef(getStorage(app), `users/${user.uid}/profile-picture`);
        await uploadBytes(storageReference, profilePicture);
        const photoURL = await getDownloadURL(storageReference);

        const db = getDatabase(app);
        const userRef = ref(db, `users/${user.uid}`);
        await update(userRef, { photoURL });

        setProfileData((prevState) => ({
          ...prevState,
          photoURL
        }));
        setProfilePicture(null);
        if (fileInputRef.current) {
          fileInputRef.current.style.backgroundImage = '';
        }
      }
    }
  };

  const handleEditProfile = () => {
    setIsEditing(true);
  };

  const handleSaveProfile = async () => {
    const auth = getAuth(app);
    const user = auth.currentUser;

    if (user) {
      const db = getDatabase(app);
      const userRef = ref(db, `users/${user.uid}`);
      await update(userRef, {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        username: profileData.username
      });
      setIsEditing(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-page">
      <h2>Profile</h2>
      {profileData.photoURL && (
        <img src={profileData.photoURL} alt="Profile" className="profile-picture" />
      )}
      {isEditing ? (
        <>
          <input
            type="text"
            value={profileData.firstName}
            onChange={(e) => setProfileData({...profileData, firstName: e.target.value})}
            placeholder="First Name"
          />
          <input
            type="text"
            value={profileData.lastName}
            onChange={(e) => setProfileData({...profileData, lastName: e.target.value})}
            placeholder="Last Name"
          />
          <input
            type="text"
            value={profileData.username}
            onChange={(e) => setProfileData({...profileData, username: e.target.value})}
            placeholder="Username"
          />
          <button onClick={handleSaveProfile}>Save Profile</button>
        </>
      ) : (
        <>
          <p>First Name: {profileData.firstName}</p>
          <p>Last Name: {profileData.lastName}</p>
          <p>Username: {profileData.username}</p>
          <p>Following: {profileData.followingCount}</p>
          <p>Followers: {profileData.followersCount}</p>
         
          <p>Products Added: {profileData.productsAddedCount}</p>
          <p>Products Favorited: {profileData.productsFavoritedCount}</p>
          <p>Reviews Added: {profileData.reviewsAddedCount}</p>
          <p>Products Favorited by Others: {profileData.productsFavoritedByOthersCount}</p>
          <button onClick={handleEditProfile}>Edit Profile</button>
        </>
      )}
      {!profilePicture && !useWebcam && (
        <div>
          <div
            ref={fileInputRef}
            className="profile-picture-preview"
            onClick={() => fileInputRef.current?.click()}
          >
            <span>Upload Profile Picture</span>
          </div>
          <button onClick={() => setUseWebcam(true)}>Take a Picture</button>
        </div>
      )}
      {useWebcam && (
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={200}
            height={200}
          />
          <button onClick={capture}>Capture</button>
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        style={{ display: profilePicture ? 'none' : 'block' }}
      

        onChange={handleProfilePictureUpload}
      />
      {croppedImage && (
        <button onClick={uploadProfilePicture}>Save Profile Picture</button>
      )}
    </div>
  );
}

export default Profile;
