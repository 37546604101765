import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaMicrophone, FaHome, FaTrash } from 'react-icons/fa';
import { getDatabase, ref, push, get } from 'firebase/database';
import { getStorage, ref as storageRef, uploadString, getDownloadURL } from 'firebase/storage';
import app from './firebaseConfig';
import './VoiceAddNewProduct.css';

function VoiceAddNewProduct() {
    const [isListening, setIsListening] = useState(false);
    const [error, setError] = useState(null);
    const [currentField, setCurrentField] = useState(null);
    const [product, setProduct] = useState({
        barcode: '',
        productDescription: '',
        brand: '',
        whereBought: '',
        tags: ''
    });
    const [image, setImage] = useState(null);
    const recognitionRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const onResultCallback = useCallback((event) => {
        const current = event.resultIndex;
        const cleanedTranscript = event.results[current][0].transcript
            .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "")
            .replace(/\bcomma\b/gi, ",")
            .trim();
        setProduct(prev => ({ ...prev, [currentField]: cleanedTranscript }));
    }, [currentField]);

    useEffect(() => {
        if ('webkitSpeechRecognition' in window) {
            const SpeechRecognition = window.webkitSpeechRecognition;
            recognitionRef.current = new SpeechRecognition();
            recognitionRef.current.continuous = false;
            recognitionRef.current.interimResults = false;
            recognitionRef.current.lang = 'en-US';

            recognitionRef.current.onresult = onResultCallback;

            recognitionRef.current.onerror = (event) => {
                if (event.error !== 'no-speech') {
                    setError(`Error: ${event.error}`);
                    setIsListening(false);
                }
            };

            recognitionRef.current.onend = () => setIsListening(false);
        } else {
            setError("Speech Recognition not supported in this browser");
        }

        if (location.state?.capturedImage) {
            setImage(location.state.capturedImage);
        }
        if (location.state?.formData) {
            setProduct(prevProduct => ({
                ...prevProduct,
                ...location.state.formData
            }));
        }

        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.abort();
            }
        };
    }, [onResultCallback, location.state]);

    const toggleListening = (field) => {
        if (isListening) {
            recognitionRef.current.stop();
            setIsListening(false);
        } else {
            setError(null);
            setCurrentField(field);
            recognitionRef.current.start();
            setIsListening(true);
        }
    };

    const checkExistingProduct = async (productDescription, brand) => {
        const db = getDatabase(app);
        const productsRef = ref(db, 'nature/products');
        const snapshot = await get(productsRef);
        
        if (snapshot.exists()) {
            const products = snapshot.val();
            return Object.values(products).some(product => 
                product.productDescription.toLowerCase() === productDescription.toLowerCase() &&
                product.brand.toLowerCase() === brand.toLowerCase()
            );
        }
        return false;
    };

    const handleSubmit = async () => {
        if (!product.barcode && !product.productDescription && !product.brand && !product.whereBought) {
            alert('Please fill out at least one field before submitting.');
            return;
        }
      
        const db = getDatabase(app);
        const storage = getStorage(app);
        const productsRef = ref(db, 'nature/products');
      
        try {
            const productExists = await checkExistingProduct(product.productDescription, product.brand);
            if (productExists) {
                alert('A product with this description and brand already exists in the database.');
                return;
            }

            let imageUrl = '';
            if (image) {
                const imageRef = storageRef(storage, `product-images/${Date.now()}.jpg`);
                await uploadString(imageRef, image, 'data_url');
                imageUrl = await getDownloadURL(imageRef);
            }
      
            const productData = {
                ...product,
                imageUrl
            };
      
            await push(productsRef, productData);
            alert('Item added successfully!');
            navigate('/');
        } catch (error) {
            console.error('Error adding product:', error.message);
            alert('Failed to add item. Please try again.');
        }
    };

    const clearTranscript = (field) => {
        setProduct(prev => ({ ...prev, [field]: '' }));
    };

    const handleTakePicture = () => {
        navigate('/take-picture', { state: { formData: product, from: 'voiceAddNewProduct' } });
    };

    const renderField = (field) => (
        <div key={field} className="field-container">
            <div className={`listening-indicator ${isListening && currentField === field ? 'active' : ''}`}>
                Listening...
            </div>
            <label>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
            <div className="input-group">
                <FaMicrophone
                    className={`mic-icon ${isListening && currentField === field ? 'active' : ''}`}
                    size={30} 
                    onClick={() => toggleListening(field)}
                />
                <input
                    type="text"
                    value={product[field]}
                    readOnly
                    placeholder={field === 'barcode' ? 'Say "unknown" if no barcode' : ''}
                />
                <FaTrash className="clear-icon" onClick={() => clearTranscript(field)} />
            </div>
        </div>
    );

    return (
        <div className="voice-add-product">
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                {['barcode include small numbers on either end', 'product Description', 'brand', 'where Seen'].map(renderField)}
                {error && <p className="error-message">{error}</p>}
                
                {!image && (
                    <button type="button" onClick={handleTakePicture} className="take-picture-button">
                        Take Picture
                    </button>
                )}

                {image && (
                    <div className="preview">
                        <img src={image} alt="Captured product" />
                        <button type="button" onClick={() => setImage(null)} className="retake-button">
                            Retake
                        </button>
                    </div>
                )}

                <div className="button-group">
                    <button 
                        type="submit" 
                        className="submit-button" 
                        disabled={!product.barcode && !product.productDescription && !product.brand && !product.whereBought}
                    >
                        Add Item
                    </button>
                    <button className="return-button" onClick={() => navigate('/home-search')}>
                        <FaHome /> Return to Search Page
                    </button>
                </div>
            </form>
            <h2 style={{ fontSize: '16px', marginTop: '10px' }}>
                Add A New Item<br />
                (with Voice)
            </h2>
        </div>
    );
}

export default VoiceAddNewProduct;