import React, { useRef, useState, useCallback, useEffect } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import {  useNavigate } from 'react-router-dom';
import {  FaExternalLinkAlt } from 'react-icons/fa';
import './BarcodeScanner.css';

const GoogleBarcodeScanner = () => {
  const videoRef = useRef(null);
  const [error, setError] = useState(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [lastScannedUrl, setLastScannedUrl] = useState(null);
  const [lastScannedBarcode, setLastScannedBarcode] = useState(null);
  const codeReaderRef = useRef(null);
  const navigate = useNavigate();
  const initializeCodeReader = useCallback(() => {
    if (!codeReaderRef.current) {
      codeReaderRef.current = new BrowserMultiFormatReader();
    }
  }, []);
  const handleReturnToSearchPage = () => {
    navigate('/home-search');
  };
  const handleCanPlay = () => {
    console.log("Video can play");
    setIsVideoReady(true);
  };

  const handleScan = useCallback((result) => {
    if (result && !isScanning) {
      const barcode = result.getText();
      console.log('Scanned Barcode:', barcode);
      
      setIsScanning(true);
      setLastScannedBarcode(barcode);
      
      const searchQuery = `${barcode} product reviews`;
      const googleSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`;
      console.log('Google Search URL:', googleSearchUrl);
      
      setLastScannedUrl(googleSearchUrl);
      
      if (codeReaderRef.current) {
        codeReaderRef.current.reset();
      }
      
      setTimeout(() => {
        setIsScanning(false);
      }, 3000);
    }
  }, [isScanning]);

  const startScanning = useCallback(async () => {
    if (!isVideoReady) {
      console.log("Video not ready yet");
      return;
    }
    try {
      initializeCodeReader();
      const videoElement = videoRef.current;
      const codeReader = codeReaderRef.current;

      setIsScanning(false);
      await codeReader.decodeFromVideoDevice(null, videoElement, handleScan);

      console.log("Decoding started");
    } catch (error) {
      console.error('Error accessing camera:', error);
      setError('Error accessing camera: ' + error.message);
    }
  }, [isVideoReady, initializeCodeReader, handleScan]);

  useEffect(() => {
    const videoElement = videoRef.current;
  
    if (videoElement) {
      videoElement.addEventListener('canplay', handleCanPlay);
  
      navigator.mediaDevices.getUserMedia({ 
        video: { facingMode: "environment" } 
      })
        .then(stream => {
          videoElement.srcObject = stream;
          console.log("Rear camera stream set to video element");
        })
        .catch(err => {
          console.error("Error accessing rear camera:", err);
          setError("Error accessing camera: " + err.message);
        });
    }
  
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('canplay', handleCanPlay);
        if (videoElement.srcObject) {
          videoElement.srcObject.getTracks().forEach(track => track.stop());
        }
      }
      if (codeReaderRef.current) {
        codeReaderRef.current.reset();
      }
    };
  }, []);

  useEffect(() => {
    if (isVideoReady && !isScanning) {
      startScanning();
    }
  }, [isVideoReady, isScanning, startScanning]);

  return (
    
    <div className="barcode-scanner">
      <div className="scanner-content">
        <h2>Scan Barcode for Google Search</h2>
        {error && <p className="error-message">{error}</p>}
        {isScanning && <p className="scan-message">Scan successful! Click the button below to open Google Search.</p>}
        {lastScannedBarcode && (
          <div className="last-scan">
            <p>Last scanned barcode: {lastScannedBarcode}</p>
            {lastScannedUrl && (
              <a 
                href={lastScannedUrl} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="open-lookup-button"
              >
                Scan Barcode for Google Search <FaExternalLinkAlt />
              </a>
            )}
          </div>
        )}
        <video 
          ref={videoRef} 
          autoPlay 
          playsInline 
          className="scanner-video"
          style={{ display: isVideoReady ? 'block' : 'none' }} 
        />
        {!isVideoReady && <p>Initializing camera...</p>}
  
      </div>
      <button onClick={handleReturnToSearchPage}>
      Return to Search Page
    </button>
    </div>
  );
};

export default GoogleBarcodeScanner;