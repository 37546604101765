import React, { useRef, useState, useCallback, useEffect } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, query, orderByChild, equalTo, get } from 'firebase/database';
import app from './firebaseConfig';
import './BarcodeScanner.css';

const BarcodeScanner = () => {
  const videoRef = useRef(null);
  const [error, setError] = useState(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const codeReaderRef = useRef(null);
  const navigate = useNavigate();

  const handleReturnToSearchPage = () => {
    navigate('/home-search');
  };

  const initializeCodeReader = useCallback(() => {
    if (!codeReaderRef.current) {
      codeReaderRef.current = new BrowserMultiFormatReader();
    }
  }, []);

  const handleCanPlay = () => {
    console.log("Video can play");
    setIsVideoReady(true);
  };

  const processBarcode = (barcode) => {
    return barcode.trim().replace(/[^0-9]/g, '');
  };

  const handleScan = useCallback(async (result) => {
    if (result) {
      const barcode = result.getText();
      console.log('Scanned Barcode:', barcode);
      console.log('Barcode Type:', result.getBarcodeFormat());
      
      const processedBarcode = processBarcode(barcode);
      console.log('Processed Barcode:', processedBarcode);
      
      try {
        const db = getDatabase(app);
        const productsRef = ref(db, 'nature/products');
        const productQuery = query(productsRef, orderByChild('barcode'), equalTo(processedBarcode));
        
        const snapshot = await get(productQuery);
        if (snapshot.exists()) {
          const products = snapshot.val();
          const productId = Object.keys(products)[0];
          navigate(`/product/${productId}`);
        } else {
          navigate(`/add-product?barcode=${processedBarcode}`);
        }
      } catch (error) {
        console.error('Error processing barcode:', error);
        setError('Error processing barcode. Please try again.');
      }
      
      // Stop scanning after successful scan
      if (codeReaderRef.current) {
        codeReaderRef.current.reset();
      }
      setIsScanning(false);
    }
  }, [navigate]);

  const startScanning = useCallback(async () => {
    if (!isVideoReady) {
      console.log("Video not ready yet");
      return;
    }
    try {
      initializeCodeReader();
      const videoElement = videoRef.current;
      const codeReader = codeReaderRef.current;

      setIsScanning(true);
      await codeReader.decodeFromVideoDevice(null, videoElement, handleScan);

      console.log("Decoding started");
    } catch (error) {
      console.error('Error accessing camera:', error);
      setError('Error accessing camera: ' + error.message);
    }
  }, [isVideoReady, initializeCodeReader, handleScan]);

  useEffect(() => {
    const videoElement = videoRef.current;
  
    if (videoElement) {
      videoElement.addEventListener('canplay', handleCanPlay);
  
      navigator.mediaDevices.getUserMedia({ 
        video: { facingMode: "environment" } 
      })
        .then(stream => {
          videoElement.srcObject = stream;
          console.log("Rear camera stream set to video element");
        })
        .catch(err => {
          console.error("Error accessing rear camera:", err);
          setError("Error accessing camera: " + err.message);
        });
    }
  
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('canplay', handleCanPlay);
        if (videoElement.srcObject) {
          videoElement.srcObject.getTracks().forEach(track => track.stop());
        }
      }
      if (codeReaderRef.current) {
        codeReaderRef.current.reset();
      }
    };
  }, []);

  useEffect(() => {
    if (isVideoReady && !isScanning) {
      startScanning();
    }
  }, [isVideoReady, isScanning, startScanning]);

  return (
    <div className="barcode-scanner">
      <div className="scanner-content">
        <h2>Scan Barcode for Database Search and to Add Item</h2>
        <h3>If an item is found, it will display it, if not you can add a new item</h3>
        {error && <p className="error-message">{error}</p>}
        <video 
          ref={videoRef} 
          autoPlay 
          playsInline 
          className="scanner-video"
          style={{ display: isVideoReady ? 'block' : 'none' }} 
        />
        {!isVideoReady && <p>Initializing camera...</p>}
        <button onClick={handleReturnToSearchPage}>
          Return to Search Page
        </button>
      </div>
    </div>
  );
};

export default BarcodeScanner;