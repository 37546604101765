import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import './TextSearch.css';

function TextSearch({ onSearch }) {
  const [searchCriteria, setSearchCriteria] = useState({
    productDescription: '',
    brand: '',
    whereSeen: '',
  });
  const [searchType, setSearchType] = useState('database');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setSearchCriteria(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const query = searchCriteria.productDescription || searchCriteria.brand || searchCriteria.whereBought;
    if (searchType === 'database') {
        navigate('/all-products', { state: { filters: searchCriteria } });
      } else {
        let url;
        switch(searchType) {
          case 'amazon':
            url = `https://www.amazon.com/s?k=${encodeURIComponent(query)}`;
            break;
          case 'reviews':
            url = `https://www.google.com/search?q=${encodeURIComponent(query + ' reviews')}`;
            break;
          case 'bing':
            url = `https://www.bing.com/search?q=${encodeURIComponent(query + ' reviews')}`;
            break;
          case 'youtube':
            url = `https://www.youtube.com/results?search_query=${encodeURIComponent(query + ' reviews')}`;
            break;
          case 'imdb':
            url = `https://www.imdb.com/find?q=${encodeURIComponent(query + ' reviews')}`;
            break;
          case 'tripadvisor':
            url = `https://www.tripadvisor.com/Search?q=${encodeURIComponent(query + ' reviews')}`;
            break;
          case 'yelp':
            url = `https://www.yelp.com/search?find_desc=${encodeURIComponent(query + ' reviews')}`;
            break;
          default:
            url = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
        }
        window.open(url, '_blank');
      }
    };
  


  const handleClearFields = () => {
    setSearchCriteria({
      productDescription: '',
      brand: '',
      whereSeen: '',
    });
  };

  return (
    <div className="text-search">
      <div className="search-content">
        <h2>Text Search</h2>
        <form onSubmit={handleSubmit}>
          {['productDescription', 'brand', 'whereSeen'].map((field) => (
            <input
              key={field}
              id={field}
              type="text"
              value={searchCriteria[field]}
              onChange={handleInputChange}
              placeholder={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
            />
          ))}

<div className="search-options">
            {['database', 'reviews', 'amazon', 'web', 'youtube', 'bing', 'imdb', 'tripadvisor', 'yelp'].map((type) => (
              <label key={type}>
                <input 
                  type="radio" 
                  value={type} 
                  checked={searchType === type} 
                  onChange={() => setSearchType(type)}
                />
                {type === 'database' ? 'Search Local Database' :
                 type === 'reviews' ? 'Google for Reviews' :
                 type === 'amazon' ? 'Search Amazon' :
                 type === 'web' ? 'Search Web in General' :
                 type === 'youtube' ? 'Search YouTube' :
                 type === 'bing' ? 'Bing for Reviews' :
                 type === 'imdb' ? 'IMDB for Movie Reviews' :
                 type === 'tripadvisor' ? 'TripAdvisor for Hotels, Restaurants and Places' :
                 'Yelp for Reviews'}
              </label>
            ))}
          </div>


          <div className="button-container">
            <button type="submit">Search</button>
            <button type="button" className="clear-button" onClick={handleClearFields}>Clear Fields</button>
          </div>
        </form>
        <button className="return-button" onClick={() => navigate('/home-search')}>
          <FaHome /> Return to Search Page
        </button>
      </div>
    </div>
  );
}

export default TextSearch;