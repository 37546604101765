import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import app from './firebaseConfig';
import './AdminHeadline.css';

const AdminHeadline = () => {
  const [headlines, setHeadlines] = useState([]);

  useEffect(() => {
    const db = getDatabase(app);
    const headlinesRef = ref(db, 'headlines');

    onValue(headlinesRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const headlinesArray = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setHeadlines(headlinesArray);
      }
    });
  }, []);

  const handleDelete = (id) => {
    const db = getDatabase(app);
    const headlineRef = ref(db, `headlines/${id}`);
    remove(headlineRef).then(() => {
      setHeadlines(headlines.filter(headline => headline.id !== id));
      alert('Headline deleted successfully!');
    }).catch((error) => {
      console.error('Error deleting headline:', error);
    });
  };

  return (
    <div className="admin-headline-page">
      <h2>Admin Headline Management</h2>
      <div className="headlines-list">
        {headlines.map(headline => (
          <div key={headline.id} className="headline-card">
            <h3>{headline.tagline}</h3>
            <p>{headline.copy}</p>
            <button onClick={() => handleDelete(headline.id)} className="delete-button">Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminHeadline;
