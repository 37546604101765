import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import './BottomNav.css';
import { FaNewspaper, FaUser, FaHeart, FaSignOutAlt, FaRegNewspaper, FaBell,} from 'react-icons/fa';

const BottomNav = ({ unreadNotificationsCount }) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getDatabase();
  const [unreadCount, setUnreadCount] = useState(unreadNotificationsCount);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      const notificationsRef = ref(db, `notifications/${user.uid}`);
      const unsubscribe = onValue(notificationsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const unread = Object.values(data).filter(notification => !notification.read).length;
          setUnreadCount(unread);
        } else {
          setUnreadCount(0);
        }
      });

      return () => unsubscribe();
    }
  }, [user, db]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (!user) {
    return null; // or return a loading spinner
  }

  return (
    <div className="bottom-nav">
      <div className="nav-item" onClick={() => navigate('/headlines')}>
        <FaNewspaper size={20} />
        <span>Ratings & Reviews</span>
      </div>

      <div className="nav-item" onClick={() => navigate('/profile')}>
        <FaUser size={20} />
        <span>Profile</span>
      </div>
      <div className="nav-item" onClick={() => navigate('/favorites')}>
        <FaHeart size={20} />
        <span>Favorites</span>
      </div>
      <div className="nav-item" onClick={() => navigate('/blog')}>
        <FaRegNewspaper size={20} />
        <span>Forum</span>
      </div>
      <div className="nav-item" onClick={() => navigate('/notifications')}>
        <FaBell size={20} className={unreadCount > 0 ? 'has-unread' : ''} />
        <span>Notifications</span>
        {unreadCount > 0 && (
          <div className="notification-badge">{unreadCount}</div>
        )}
      </div>

      <div className="nav-item" onClick={handleLogout}>
        <FaSignOutAlt size={20} />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default BottomNav;