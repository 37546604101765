import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import app from './firebaseConfig';
import HeadlineCard from './HeadlineCard';
import './Headlines.css';

const Headlines = () => {
  const [headlines, setHeadlines] = useState([]);
  const [filteredHeadlines, setFilteredHeadlines] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const db = getDatabase(app);
    const headlinesRef = ref(db, 'headlines');

    onValue(headlinesRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const headlinesArray = Object.keys(data).map(key => ({
          id: key,
          ...data[key],
          createdAt: data[key].createdAt || key
        }));
        headlinesArray.sort((a, b) => b.createdAt - a.createdAt);
        setHeadlines(headlinesArray);
        setFilteredHeadlines(headlinesArray);
      }
    });
  }, []);

  const handleSearch = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);

    if (searchText === '') {
      setFilteredHeadlines(headlines);
    } else {
      const filtered = headlines.filter(headline =>
        headline.tagline?.toLowerCase().includes(searchText)
      );
      setFilteredHeadlines(filtered);
    }
  };

  return (
    <div className="headlines-page">
      <div className="header">
        <h2>Ratings and Reviews</h2>
        <input
          type="text"
          placeholder="Search Ratings and Reviews"
          value={searchText}
          onChange={handleSearch}
          className="search-input"
        />
      </div>
      <div className="headlines-list">
        {filteredHeadlines.length > 0 ? (
          filteredHeadlines.map(headline => (
            <HeadlineCard key={headline.id} headline={headline} />
          ))
        ) : (
          <div>No headlines found.</div>
        )}
      </div>
    </div>
  );
};

export default Headlines;