import React from 'react';
import { FaStar } from 'react-icons/fa';
import './StarRating.css';

const StarRating = ({ rating, onRatingChange, readonly = false, size = 20 }) => {
  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <label key={index}>
            {!readonly && (
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => onRatingChange(ratingValue)}
              />
            )}
            <FaStar
              className="star"
              color={ratingValue <= rating ? "#ffc107" : "#a3a4a5"}
              size={size}
            />
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;