import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDatabase, ref, push, get } from 'firebase/database';
import { getStorage, ref as storageRef, uploadString, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import app from './firebaseConfig';
import './AddNewProduct.css';

const AddNewProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);
  const [barcode, setBarcode] = useState(location.state?.formData?.barcode || '');
  const [productDescription, setProductDescription] = useState(location.state?.formData?.productDescription || '');
  const [brand, setBrand] = useState(location.state?.formData?.brand || '');
  const [whereBought, setWhereBought] = useState(location.state?.formData?.whereBought || '');
  const [image, setImage] = useState(location.state?.capturedImage || null);
  const [currentUser, setCurrentUser] = useState(null);

  const checkExistingProduct = async (productDescription, brand) => {
    const db = getDatabase(app);
    const productsRef = ref(db, 'nature/products');
    const snapshot = await get(productsRef);
    
    if (snapshot.exists()) {
      const products = snapshot.val();
      return Object.values(products).some(product => 
        product.productDescription.toLowerCase() === productDescription.toLowerCase() &&
        product.brand.toLowerCase() === brand.toLowerCase()
      );
    }
    return false;
  };

  useEffect(() => {
    const auth = getAuth();
    setCurrentUser(auth.currentUser);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialBarcode = params.get('barcode');
    if (initialBarcode) {
      setBarcode(initialBarcode);
    }
  }, [location]);

  const handleReturnToSearchPage = () => {
    navigate('/home-search');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      alert('You must be logged in to add a product.');
      navigate('/login');
      return;
    }

    if (barcode.trim() === '') {
      alert('Please enter a valid barcode or "unknown".');
      return;
    }

    if (image && !image.startsWith('data:image/')) {
      alert('Invalid image format. Please upload a valid image.');
      return;
    }

    const db = getDatabase(app);
    const storage = getStorage(app);
    const productsRef = ref(db, 'nature/products');

    try {
      const productExists = await checkExistingProduct(productDescription, brand);
      if (productExists) {
        alert('A product with this description and brand already exists in the database.');
        return;
      }

      let imageUrl = '';
      if (image) {
        const imageRef = storageRef(storage, `product-images/${Date.now()}.jpg`);
        await uploadString(imageRef, image, 'data_url');
        imageUrl = await getDownloadURL(imageRef);
      }

      const currentDate = new Date().toISOString();

      const newProduct = {
        barcode,
        productDescription,
        brand,
        whereBought,
        imageUrl,
        addedBy: currentUser ? currentUser.uid : 'Anonymous',
        dateAdded: currentDate
      };

      console.log('Attempting to add product:', newProduct);

      const newProductRef = await push(productsRef, newProduct);
      console.log('Product added successfully with key:', newProductRef.key);

      alert('Product added successfully!');
      navigate('/all-products');
    } catch (error) {
      console.error('Error adding product:', error);
      alert(`Failed to add product. Error: ${error.message}`);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="add-new-product">
      <h2>Add New Product</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Barcode (type unknown if no barcode)"
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
          required
        />
        <textarea
          placeholder="Product Description"
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Brand"
          value={brand}
          onChange={(e) => setBrand(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Where Seen"
          value={whereBought}
          onChange={(e) => setWhereBought(e.target.value)}
          required
        />
        
        {!image && (
          <>
            <button type="button" onClick={() => navigate('/take-picture', { state: { formData: { barcode, productDescription, brand, whereBought } } })}>Take Picture</button>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <button type="button" onClick={() => fileInputRef.current.click()}>Upload Existing Picture</button>
          </>
        )}
        
        {image && (
          <div className="preview">
            <img src={image} alt="Captured product" />
            <button type="button" onClick={() => setImage(null)}>Remove Image</button>
          </div>
        )}
        
        <button type="submit">Add Product</button>
        <button type="button" onClick={handleReturnToSearchPage}>
          Return to Search Page
        </button>
      </form>
    </div>
  );
};

export default AddNewProduct;
