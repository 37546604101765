import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set, onValue } from 'firebase/database';
//import { useNavigate } from 'react-router-dom';
import app from './firebaseConfig';
import './CreateHeadline.css';
import logo from './review-explorer-logo.png'; // Import the logo

const CreateHeadline = () => {
  const [tagline, setTagline] = useState('');
  const [copy, setCopy] = useState('');
  const [productId, setProductId] = useState('');
  const [link, setLink] = useState('');
  const [products, setProducts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
 // const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase(app);
    const productsRef = ref(db, 'nature/products');

    onValue(productsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const productsArray = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        })).sort((a, b) => {
          if (a.productName && b.productName) {
            return a.productName.localeCompare(b.productName);
          }
          return 0;
        });
        setProducts(productsArray);
      }
    });
  }, []);

  const handleAddHeadline = () => {
    const db = getDatabase(app);
    const newHeadlineRef = ref(db, `headlines/${Date.now()}`);
    set(newHeadlineRef, {
      tagline,
      copy,
      productId,
      link,
      createdAt: Date.now() // Add this line
    }).then(() => {
      setTagline('');
      setCopy('');
      setProductId('');
      setLink('');
      alert('Headline added successfully!');
    }).catch((error) => {
      console.error('Error adding headline:', error);
    });
  };

  const handleProductSelect = (id) => {
    setProductId(id);
    setShowDropdown(false);
  };

  return (
    <div className="create-headline-page">
      <header className="header">
        <img src={logo} alt="Review Explorer Logo" className="logo" />
        <h1>Welcome to Favritz!</h1>
        <p>Create a new headline below.</p>
      </header>
      <div className="form-group">
        <label>Tagline:</label>
        <input
          type="text"
          value={tagline}
          onChange={(e) => setTagline(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Copy:</label>
        <textarea
          value={copy}
          onChange={(e) => setCopy(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Product ID:</label>
        <input
          type="text"
          value={productId}
          onChange={(e) => setProductId(e.target.value)}
        />
        <button onClick={() => setShowDropdown(!showDropdown)}>Search Database</button>
        {showDropdown && (
          <div className="dropdown">
            {products.map(product => (
              <div key={product.id} onClick={() => handleProductSelect(product.id)}>
                {product.productName} ({product.id})
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="form-group">
        <label>Link:</label>
        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
      </div>
      <button onClick={handleAddHeadline}>Add to Headlines</button>
    </div>
  );
};

export default CreateHeadline;
