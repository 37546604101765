import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HeadlineCard.css';

const HeadlineCard = ({ headline }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (headline.link) {
      window.open(headline.link, '_blank');
    } else {
      navigate(`/productDetail/${headline.productId}`);
    }
  };

  // Format the date
  const formattedDate = new Date(headline.createdAt).toLocaleDateString();

  return (
    <div className="headline-card" onClick={handleClick}>
      <h3>{headline.tagline}</h3>
      <p>{headline.copy}</p>
      <p className="creation-date">Created on: {formattedDate}</p>
    </div>
  );
};

export default HeadlineCard;