import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddReview from './AddReview';
import StarDisplay from './StarDisplay';
import { getDatabase, ref, remove } from 'firebase/database';
import app from './firebaseConfig';
import './ProductReview.css';

const ProductReview = ({ products, scannedBarcode, onReviewAdded, onReviewDeleted, onProductDeleted, isLoading }) => {
  const navigate = useNavigate();
  const [showAddReview, setShowAddReview] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productsWithRatings, setProductsWithRatings] = useState([]);

  useEffect(() => {
    const calculateAverageRatings = () => {
      const updatedProducts = products.map(product => {
        if (product.reviews && product.reviews.length > 0) {
          const totalRating = product.reviews.reduce((sum, review) => sum + Number(review.rating), 0);
          const averageRating = totalRating / product.reviews.length;
          return { ...product, averageRating };
        }
        return { ...product, averageRating: 0 };
      });
      setProductsWithRatings(updatedProducts);
    };

    calculateAverageRatings();
  }, [products]);

  const handleDeleteReview = async (productId, reviewId) => {
    if (window.confirm('Are you sure you want to delete this review?')) {
      const db = getDatabase(app);
      const reviewRef = ref(db, `nature/products/${productId}/reviews/${reviewId}`);
      try {
        await remove(reviewRef);
        alert('Review deleted successfully!');
        onReviewDeleted();
      } catch (error) {
        console.error('Error deleting review:', error);
        alert('Failed to delete review. Please try again.');
      }
    }
  };

  const handleDeleteProduct = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      const db = getDatabase(app);
      const productRef = ref(db, `nature/products/${productId}`);
      try {
        await remove(productRef);
        alert('Product deleted successfully!');
        onProductDeleted();
        setSelectedProduct(null);
      } catch (error) {
        console.error('Error deleting product:', error);
        alert('Failed to delete product. Please try again.');
      }
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (products.length === 0) {
    return (
      <div className="product-not-found">
        <h2>Product not found</h2>
        <p>Would you like to add a new product or return to the home page?</p>
        <div className="button-group">
          <button onClick={() => navigate(`/add-product?barcode=${scannedBarcode}`)}>Add New Product</button>
          <button onClick={() => navigate('/')}>Back to Home</button>
        </div>
      </div>
    );
  }

  if (selectedProduct) {
    console.log('Selected Product:', selectedProduct); // Debugging line
    return (
      <div className="product-review">
        <h2>{selectedProduct.productName}</h2>
        <div className="product-details">
          {selectedProduct.imageUrl && (
            <img src={selectedProduct.imageUrl} alt={selectedProduct.productName} className="product-image" />
          )}
          <div className="product-info">
            <p><strong>Brand:</strong> {selectedProduct.brand}</p>
            <p><strong>Barcode:</strong> {selectedProduct.barcode}</p>
            <p><strong>Where Bought:</strong> {selectedProduct.whereBought}</p>
            {selectedProduct.tags && selectedProduct.tags.length > 0 && (
              <div className="product-tags">
                <strong>Tags:</strong> {selectedProduct.tags.join(', ')}
              </div>
            )}
          </div>
        </div>
        
        <details>
          <summary>Description</summary>
          <p>{selectedProduct.productDescription}</p>
        </details>
  
        <h3>Reviews</h3>
        {selectedProduct.reviews && selectedProduct.reviews.length > 0 ? (
          <div className="reviews-list">
            {selectedProduct.reviews.map((review) => (
              <div key={review.id} className="review-item">
                <div className="review-header">
                  <StarDisplay rating={Number(review.rating)} />
                  <span className="review-date">{new Date(review.timestamp).toLocaleDateString()}</span>
                </div>
                <p className="review-text">{review.review}</p>
                <button className="delete-btn" onClick={() => handleDeleteReview(selectedProduct.id, review.id)}>
                  Delete
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p>No reviews yet.</p>
        )}
        
        <div className="action-buttons">
          <button className="delete-product-btn" onClick={() => handleDeleteProduct(selectedProduct.id)}>
            Delete Item
          </button>
          {!showAddReview && (
            <button className="add-review-btn" onClick={() => setShowAddReview(true)}>Add Review</button>
          )}
          <button className="back-btn" onClick={() => setSelectedProduct(null)}>Back to List</button>
        </div>
        
        {showAddReview && (
          <AddReview 
            productId={selectedProduct.id} 
            onReviewAdded={() => {
              setShowAddReview(false);
              onReviewAdded();
            }} 
          />
        )}
      </div>
    );
  }
 
  // Multiple products view
  return (
    <div className="product-list">
      <h2>Products Found</h2>
      <h3 className="centered-instruction">Click product to view or add review</h3>
      <button className="back-btn" onClick={() => navigate('/')}>Back to Home</button>
      <div className="product-grid">
        {productsWithRatings.map(product => (
          <div key={product.id} className="product-card" onClick={() => setSelectedProduct(product)}>
            {product.imageUrl && (
              <img src={product.imageUrl} alt={product.productName} className="product-thumbnail" />
            )}
            <h3>{product.productName}</h3>
            <p><strong>Brand:</strong> {product.brand}</p>
            <p><strong>Barcode:</strong> {product.barcode}</p>
            <p><strong>Where Bought:</strong> {product.whereBought}</p>
            {product.tags && product.tags.length > 0 && (
              <div className="product-tags">
                <strong>Tags:</strong>
                <ul className="tag-list">
                  {product.tags.map((tag, index) => (
                    <li key={index} className="tag">{tag}</li>
                  ))}
                </ul>
              </div>
            )}
            {/* Add StarDisplay component here */}
            <StarDisplay rating={Number(product.averageRating)} />
            {console.log('Product Rating:', product.averageRating)} {/* Debugging line */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductReview;
