import React, { useEffect, useState } from 'react';
import { getDatabase, ref as dbRef, get, set, remove } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './UserList.css';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [following, setFollowing] = useState({});
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();
    const usersRef = dbRef(db, 'users');
    const currentUser = auth.currentUser;

    if (currentUser) {
      setCurrentUser(currentUser);

      // Fetch all users
      get(usersRef).then((snapshot) => {
        if (snapshot.exists()) {
          const usersData = snapshot.val();
          const usersList = Object.keys(usersData).map((key) => ({
            uid: key,
            ...usersData[key],
          }));
          setUsers(usersList);
        }
      });

      // Fetch following list
      const followingRef = dbRef(db, `follows/${currentUser.uid}`);
      get(followingRef).then((snapshot) => {
        if (snapshot.exists()) {
          setFollowing(snapshot.val());
        }
      });
    }
  }, [auth]);

  const handleFollow = (targetUserId) => {
    if (currentUser) {
      const db = getDatabase();
      const followRef = dbRef(db, `follows/${currentUser.uid}/${targetUserId}`);
      const followerRef = dbRef(db, `followers/${targetUserId}/${currentUser.uid}`);

      set(followRef, true);
      set(followerRef, true);
      setFollowing((prev) => ({ ...prev, [targetUserId]: true }));
    }
  };

  const handleUnfollow = (targetUserId) => {
    if (currentUser) {
      const db = getDatabase();
      const followRef = dbRef(db, `follows/${currentUser.uid}/${targetUserId}`);
      const followerRef = dbRef(db, `followers/${targetUserId}/${currentUser.uid}`);

      remove(followRef);
      remove(followerRef);
      setFollowing((prev) => {
        const updated = { ...prev };
        delete updated[targetUserId];
        return updated;
      });
    }
  };

  const handleUserClick = (username) => {
    navigate(`/user-products/${encodeURIComponent(username)}`);
  };

  return (
    <div>
      <h2>User List</h2>
      <ul>
        {users.map((user) => (
          <li key={user.uid}>
            <span onClick={() => handleUserClick(user.username)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
              {user.firstName} {user.lastName}
            </span>
            {following[user.uid] ? (
              <button onClick={() => handleUnfollow(user.uid)}>Unfollow</button>
            ) : (
              <button onClick={() => handleFollow(user.uid)}>Follow</button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;
