import React from 'react';
import './StarDisplay.css';

const StarDisplay = ({ rating }) => {
  console.log('Rating:', rating); // Debugging line
  return (
    <div className="star-display">
      {[...Array(5)].map((star, index) => (
        <span
          key={index}
          className={`star ${index < rating ? 'filled' : 'empty'}`}
        >
          ★
        </span>
      ))}
    </div>
  );
};

export default StarDisplay;
