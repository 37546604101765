import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaBarcode, FaUserFriends, FaUsers, FaInfoCircle, FaEnvelope } from 'react-icons/fa';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';
import './TopNavBar.css';

const TopNavBar = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (!user) {
    return null; // Don't render anything if user is not logged in
  }

  return (
    <div className="top-nav-bar">
      <div className="nav-items">
      <div className="nav-item" onClick={() => navigate('/all-products')}>
          <FaHome size={20} />
          <span>Home Database</span>
        </div>
        <div className="nav-item" onClick={() => navigate('/user-list')}>
          <FaUsers size={20} />
          <span>User List</span>
        </div>
        <div className="nav-item" onClick={() => navigate('/following')}>
          <FaUserFriends size={20} />
          <span>Following</span>
        </div>
        <div className="nav-item" onClick={() => navigate('/home-search')}>
          <FaBarcode size={20} />
          <span>Scan Barcodes</span>
        </div>
   
        <div className="nav-item" onClick={() => navigate('/about')}>
          <FaInfoCircle size={20} />
          <span>About Us</span>
        </div>
        <div className="nav-item" onClick={() => navigate('/contact')}>
          <FaEnvelope size={20} />
          <span>Contact</span>
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;