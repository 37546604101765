import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <h1>About Us</h1>
      <p>A while ago I was shopping at Costco and I was looking at all of these unfamiliar products on the shelf.</p>
      <p>I thought to myself, "Wouldn't it be great if you could point your phone at a product and see what people think about it? See if there are any reviews, whether good or bad, about the product?</p>
      <p>After searching for some kind of app that does that, I couldn't find anything so I decided to create one for myself.  This app is the culmination of months of work to integrate multiple ways of searching for something to read reviews about it.</p>
     
      <p>I have since expanded the idea to be able to search for just about anything and find reviews: Product, places, restaurants, attractions, you name it.  </p>
      <h2>Things the App can do</h2>
      <ul>
        <li>Scan a product barcode in the store to see reviews</li>
        <li>Read reviews about products in our database</li>
        <li>Search places like Amazon, Google, YouTube, the Web, Bing, etc. all from one page</li>
        <li>Search using your voice</li>
        <li>Add new products you would like to see</li>
        <li>Add reviews about products</li>
        <li>Share things like recipes or other things in the Forum</li>
        <li>View daily reviews from different places on the web</li>
        <li>Follow other people to see what they like</li>
        <li>Save you favorite products</li>
        <li>More to come in the future!</li></ul>
        
    </div>
  );
};

export default AboutUs;