import React, { useState, useEffect, useRef } from 'react';
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Notifications.css';
import useSound from 'use-sound';
import notificationSound from './notification.mp3';

const Notifications = ({ onNewNotification }) => {
  const [play] = useSound(notificationSound);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getDatabase();
  const prevNotificationsRef = useRef();

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const notificationsRef = ref(db, `notifications/${user.uid}`);
      const unsubscribe = onValue(notificationsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const notificationList = Object.entries(data).map(([id, notification]) => ({
            id,
            ...notification,
          }));
          
          // Sort notifications by timestamp in descending order
          notificationList.sort((a, b) => b.timestamp - a.timestamp);
          
          // Check if there are new notifications
          const prevNotifications = prevNotificationsRef.current || [];
          const newNotifications = notificationList.filter(
            notification => !prevNotifications.some(prevNotif => prevNotif.id === notification.id)
          );
          
          // Play sound if there are new notifications
          if (newNotifications.length > 0) {
            play();
          }
          
          setNotifications(notificationList);
          const unreadCount = notificationList.filter(notification => !notification.read).length;
          onNewNotification(unreadCount);
          
          // Update the ref with the current notifications
          prevNotificationsRef.current = notificationList;
        } else {
          setNotifications([]);
          onNewNotification(0);
        }
      });

      return () => unsubscribe();
    }
  }, [auth.currentUser, db, onNewNotification, play]);

  const handleNotificationClick = (notification) => {
    const user = auth.currentUser;
    if (user) {
      const notificationRef = ref(db, `notifications/${user.uid}/${notification.id}`);
      remove(notificationRef)
        .then(() => {
          console.log('Notification removed');
          
          if (notification.type === 'newPost') {
            navigate(`/blog#${notification.postId}`);
          } else if (notification.type === 'newComment') {
            navigate(`/blog#${notification.postId}-${notification.commentId}`);
          }
  
          const updatedNotifications = notifications.filter(n => n.id !== notification.id);
          setNotifications(updatedNotifications);
          const unreadCount = updatedNotifications.filter(n => !n.read).length;
          onNewNotification(unreadCount);
        })
        .catch((error) => {
          console.error('Error removing notification:', error);
        });
    }
  };

  const showNotification = (message) => {
    if (Notification.permission === 'granted') {
      new Notification(message);
    }
  };

  useEffect(() => {
    notifications.forEach(notification => {
      if (!notification.read) {
        showNotification(notification.message);
      }
    });
  }, [notifications]);

  return (
    <div className="notifications-container">
      <h1>Notifications</h1>
      {notifications.length === 0 ? (
        <p>No notifications</p>
      ) : (
        notifications.map((notification) => (
          <div
            key={notification.id}
            className={`notification ${notification.read ? '' : 'unread'}`}
            onClick={() => handleNotificationClick(notification)}
          >
            <p>{notification.message}</p>
            <small>{new Date(notification.timestamp).toLocaleString()}</small>
          </div>
        ))
      )}
    </div>
  );
};

export default Notifications;