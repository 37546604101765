import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://formspree.io/f/xwpejobr', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...formData,
        _replyto: formData.email,
        _subject: `New contact form submission from ${formData.name}`,
        _to: 'prazanj@gmail.com'
      })
    });

    if (response.ok) {
      setIsSubmitted(true);
      setFormData({ name: '', email: '', message: '' });
    } else {
      alert('There was an error submitting the form. Please try again.');
    }
  };

  if (isSubmitted) {
    return (
      <div className="contact">
        <h1>Contact Us</h1>
        <p>Thank you for your message. We'll get back to you soon!</p>
        <p>If you don't see a confirmation email in your inbox soon, please check your Updates folder or Spam folder. Some email providers may categorize our response as an update or promotional message.</p>
      </div>
    );
  }

  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <p>We'd love to hear from you! Please fill out the form below to get in touch.</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default Contact;