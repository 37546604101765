import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getDatabase, ref, query, orderByChild, equalTo, onValue, get } from 'firebase/database';
import app from './firebaseConfig';
import StarRating from './StarRating';
import './UserProducts.css';

const UserProducts = () => {
  const { username } = useParams();
  const location = useLocation();
  const showFavorites = location.state?.showFavorites || false;
  const [products, setProducts] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase(app);

    const fetchUserId = async () => {
      const decodedUsername = decodeURIComponent(username);
      const usersRef = ref(db, 'users');
      const userQuery = query(usersRef, orderByChild('username'), equalTo(decodedUsername));
      const userSnapshot = await get(userQuery);
      const userData = userSnapshot.val();
      if (!userData) {
        throw new Error(`User with username ${decodedUsername} not found`);
      }
      return Object.keys(userData)[0];
    };

    const fetchUserProducts = async (userId) => {
      const productsRef = ref(db, 'nature/products');
      const userProductsQuery = query(productsRef, orderByChild('addedBy'), equalTo(userId));

      onValue(userProductsQuery, (snapshot) => {
        const data = snapshot.val();
        const userProducts = [];
        for (const productId in data) {
          const product = data[productId];
          let averageRating = 0;
          if (product.reviews) {
            const ratings = Object.values(product.reviews).map(review => Number(review.rating));
            averageRating = ratings.reduce((a, b) => a + b, 0) / ratings.length;
          }
          userProducts.push({ ...product, productId, averageRating });
        }
        setProducts(userProducts);
      }, (error) => {
        console.error("Error fetching user products:", error);
        setError(error.message);
      });
    };

    const fetchUserFavorites = async (userId) => {
      try {
        const favoritesRef = ref(db, `users/${userId}/favorites`);
        const favoritesSnapshot = await get(favoritesRef);
        const favoritesData = favoritesSnapshot.val();
        
        if (favoritesData) {
          const favoriteProducts = await Promise.all(
            Object.keys(favoritesData).map(async (favoriteId) => {
              const productId = favoritesData[favoriteId].productId;
              const productSnapshot = await get(ref(db, `nature/products/${productId}`));
              const productData = productSnapshot.val();
              if (!productData) {
                console.log(`Product ${productId} not found, it may have been deleted.`);
                return null;
              }
              let averageRating = 0;
              if (productData.reviews) {
                const ratings = Object.values(productData.reviews).map(review => Number(review.rating));
                averageRating = ratings.reduce((a, b) => a + b, 0) / ratings.length;
              }
              return { ...productData, productId, averageRating };
            })
          );
          setFavorites(favoriteProducts.filter(product => product !== null));
        }
      } catch (error) {
        console.error("Error fetching user favorites:", error);
        setError(error.message);
      }
    };

    const initializeData = async () => {
      try {
        const userId = await fetchUserId();
        await fetchUserProducts(userId);
        if (showFavorites) {
          await fetchUserFavorites(userId);
        }
      } catch (error) {
        console.error("Error initializing data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();

  }, [username, showFavorites]);

  const renderProductCard = (product, index) => (
    <div key={index} className="user-product-card">
      <div className="user-product-image-container">
        {product.imageUrl && <img src={product.imageUrl} alt="Product" className="user-product-image" />}
      </div>
      <div className="user-product-details">
        {product.productDescription && <h3>{product.productDescription}</h3>}
        {product.brand && <p>Brand: {product.brand}</p>}
        {product.whereBought && <p>Where seen: {product.whereBought}</p>}
        <StarRating rating={product.averageRating || 0} onRatingChange={() => {}} readonly size={15} />
        <p className="added-info">
          Added on: {new Date(product.dateAdded).toLocaleDateString()}
        </p>
      </div>
      <div className="user-product-actions">
        <button onClick={() => navigate(`/product/${product.productId}`)}>View Reviews</button>
      </div>
    </div>
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="user-products-page">
      <div className="horizontal-sidebar">
        <h2>Products by {decodeURIComponent(username)}</h2>
        <button className="return-button" onClick={() => navigate('/all-products')}>Return to All Products</button>
        <button onClick={() => navigate('/user-list')}>Back to User List</button>
      </div>
      <div className="user-products-container">
        <div className="main-content">
          <h3>Posted Products</h3>
          <div className="user-products-grid">
            {products.map((product, index) => renderProductCard(product, index))}
          </div>
          {showFavorites && (
            <>
              <h3>Favritzed Products</h3>
              <div className="user-favorites-grid">
                {favorites.map((product, index) => renderProductCard(product, index))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProducts;