import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import './SearchUserName.css';

const SearchUserName = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  
  const db = getDatabase();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('Auth state changed:', user); // Logging user info
      if (user) {
        const followingRef = ref(db, `users/${user.uid}/following`);
        get(followingRef).then((snapshot) => {
          if (snapshot.exists()) {
            console.log('Following users:', snapshot.val()); // Logging followed users
          }
        });
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await fetch('http://localhost:4000/api/users');
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        const users = await response.json();
        console.log('Fetched users:', users); // Logging fetched users
        setAllUsers(users);
        setFilteredUsers(users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchAllUsers();
  }, []);

  useEffect(() => {
    console.log('Filtering users with:', searchTerm); // Logging search term
    const filtered = allUsers.filter(user => 
      user.displayName && user.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    console.log('Filtered users:', filtered); // Logging filtered users
    setFilteredUsers(filtered);
  }, [searchTerm, allUsers]);

  return (
    <div>
      <input
        type="text"
        placeholder="Search Username"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div>
        {filteredUsers.map(user => (
          <div key={user.uid}>
            <p>{user.displayName}</p>
            {/* Add follow/unfollow buttons here */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchUserName;
